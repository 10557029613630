import React from 'react'
import { Img } from 'styleguide/packages/components/index.js'

const Preview = props => {
  const { files } = props

  return files.map(file => (
    <div key={file.url} className="file-upload-preview">
      <Img crossOrigin="Anonymous" src={file.url} />
    </div>
  ))
}

export default Preview
