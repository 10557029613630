import React from 'react'
import PropTypes from 'prop-types'

export default function Track({ disabled, source, target, getTrackProps }) {
  return (
    <div
      className="slider-track"
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        backgroundColor: disabled ? 'rgba(91, 91, 91, 1)' : '#fff',
      }}
      {...getTrackProps()}
    />
  )
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

Track.defaultProps = {
  disabled: false,
}
