import React from 'react'
import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'
import { classes } from 'styleguide/packages/util/index.js'

const TelephoneInput = props => {
  const { errors, name, isGray, placeholder = '', alwaysShowMask, defaultRules, control } = props

  return (
    <Controller
      id={name}
      name={name}
      control={control}
      rules={defaultRules}
      render={({ field }) => (
        <InputMask mask="(999) 999-9999" maskChar=" " alwaysShowMask={alwaysShowMask} {...field}>
          {inputProps => (
            <input
              {...inputProps}
              aria-invalid={errors[name] ? 'true' : 'false'}
              className={classes([isGray && 'gray-field'])}
              type="text"
              placeholder={placeholder}
            />
          )}
        </InputMask>
      )}
    />
  )
}

export default TelephoneInput
