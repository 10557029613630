import React from 'react'
import PropTypes from 'prop-types'

export default function SliderRail({ getRailProps }) {
  return (
    <>
      <div className="rail-outer-style" {...getRailProps()} />
      <div className="rail-inner-style" />
    </>
  )
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
}
