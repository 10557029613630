import React from 'react'
import PropTypes from 'prop-types'

export default function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
}) {
  return (
    <>
      <div
        className="handle-click"
        style={{
          left: `${percent}%`,
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        className="slider-handle"
        style={{
          left: `${percent}%`,
          backgroundColor: disabled ? 'rgba(91, 91, 91, 1)' : '#fff',
        }}
      />
    </>
  )
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

Handle.defaultProps = {
  disabled: false,
}
