import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider'
import { Handle, SliderRail, Track } from './components/index.js'
import { useForm, Controller } from 'react-hook-form'

const sliderStyle = {
  position: 'relative',
  width: '100%',
  touchAction: 'none',
}

/**
 * Custom Slider for your custom needs
 * @constructor
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {integer} max
 * @param {integer} min
 * @param {string} name - The name of the field
 * @param {function} onChange
 * @param {function} onUpdate
 * @param {boolean} setFormValueWhileDragging
 * @param {integer} stepSize
 * @param {array} value
 *
 * Values must be arrays as slider needs to map through them
 */
const AeditSliderBase = props => {
  const {
    className = '',
    max = 100,
    min = -100,
    name,
    onChange,
    onUpdate,
    setFormValueWhileDragging = true,
    showValue = false,
    stepSize = 1,
    form,
    isDisabled = false,
  } = props

  if (isNaN(stepSize)) {
    throw new Error('stepSize must be a number')
  }

  const { watch, register, unregister, setValue } = form
  const formValue = watch()[name]
  const domain = useMemo(() => [min, max], [min, max])

  const handleChange = useCallback(
    values => setValue(name, values, { shouldDirty: true }),
    [setValue, name]
  )

  useEffect(() => {
    register(name)

    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  // call external onChange/onUpdate when field value changes
  useEffect(() => {
    if (onUpdate) {
      onUpdate(formValue)
    }
    if (onChange) {
      onChange(formValue)
    }
  }, [formValue])

  return (
    <div className={`slider ${className}`}>
      <Slider
        mode={1}
        domain={domain}
        rootStyle={sliderStyle}
        disabled={isDisabled}
        onChange={handleChange}
        onUpdate={setFormValueWhileDragging ? handleChange : undefined}
        step={stepSize}
        values={formValue}>
        <Rail>
          {({ getRailProps }) => (
            <div className={`slider-rail ${isDisabled ? 'slider-rail--disabled' : ''}`}>
              <SliderRail getRailProps={getRailProps} />
            </div>
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className={`slider-handles ${isDisabled ? 'slider-handles--disabled' : ''}`}>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  disabled={isDisabled}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className={`slider-tracks ${isDisabled ? 'slider-tracks--disabled' : ''}`}>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  disabled={isDisabled}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  )
}

AeditSliderBase.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  stepped: PropTypes.bool,
  stepSize: PropTypes.number,
}

const AeditSlider = props => {
  const { name } = props
  const { control } = useForm()

  return <Controller control={control} name={name} render={() => <AeditSliderBase {...props} />} />
}

export default AeditSlider
