import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { startCase } from 'lodash'
import PropTypes from 'prop-types'
import { classes } from 'styleguide/packages/util/index.js'
import TelephoneInput from './custom_inputs/telephone_input'
import FileUpload from '../file_upload'
import Slider from './custom_inputs/slider'

const checkInputType = props => {
  let { type = 'text', form, rules, errors, name, placeholder, isRequired, isGray } = props
  let { register } = form

  // Validation rules
  let urlRules = {
    value:
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    message: 'Please enter a valid URL',
  }

  let emailRules = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Please enter a valid email address',
  }

  let telephoneRules = {
    value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
    message: 'Please enter a valid phone number',
  }

  let defaultRules = {
    ...rules,
    required: isRequired ? 'This is required' : false,
    pattern: /url/.test(type)
      ? urlRules
      : /email/.test(type)
      ? emailRules
      : /telephone/.test(type)
      ? telephoneRules
      : {},
  }

  switch (true) {
    case /textarea/.test(type):
    // return <TextareaAutosize style={{ resize: 'none' }} rows={3} {...props} {...field} />

    case /number/.test(type):
    // return <InputMask name={name} mask="99" maskChar=" " rules={rules} ref={register(rules)} {...props} />

    case /telephone/.test(type):
      return <TelephoneInput defaultRules={defaultRules} {...props} />

    case /file/.test(type):
      return <FileUpload rules={rules} {...props} />

    case /range/.test(type):
      return <Slider rules={rules} {...props} />
    default:
      return (
        <input
          aria-invalid={errors[name] ? 'true' : 'false'}
          id={name}
          name={name}
          className={classes([isGray && 'gray-field'])}
          type={type}
          placeholder={placeholder}
          {...register(name, defaultRules)}
        />
      )
  }
}

const Input = props => {
  const {
    className = '',
    label = '',
    name,
    showCharCount = false,
    isRequired = false,
    maxLength = null,
    errors,
    helpText,
    showValue = false,
  } = props

  const form = useFormContext()
  props = { ...props, form }

  return (
    <div className={`BEURO__aedit-input-field ${className}`}>
      <div
        className={classes([
          'input-wrapper',
          `${className}--input-wrapper`,
          errors[name] && 'active-error',
        ])}>
        <label htmlFor={name} className={classes([isRequired && 'required'])}>
          {`${label || startCase(name)}`}
          {showValue ? <p className="slider-info-value">{form.watch()[name]}%</p> : <></>}
        </label>
        {helpText && <p className="BEURO__body BEURO__body--small help-text">{helpText}</p>}

        {checkInputType(props)}

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className="BEURO__body BEURO__body--small state-label">{message}</p>
          )}
        />

        {/* <span className="char-length">{showCharCount ? `${inputCount} / ${maxLength}` : null}</span> */}
      </div>
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default Input
