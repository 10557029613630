import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { classes } from 'styleguide/packages/util/index.js'

const Form = props => {
  const { id = '', className = '', onSubmit, defaultValues, children } = props
  const { isGray = false, isResetOnSubmit = false, singleInput = false } = props // booleans
  const methods = useForm({ defaultValues })

  useEffect(() => {
    methods.reset()
  }, [defaultValues])

  useEffect(() => {
    if (
      Object.entries(methods.formState.errors).length === 0 &&
      methods.formState.isValid &&
      methods.formState.isSubmitted &&
      isResetOnSubmit
    ) {
      methods.reset()
    }
  }, [methods.formState.isSubmitting])

  return (
    // FormProvider wrapper for useFormContext https://react-hook-form.com/api#useFormContext
    <FormProvider {...methods}>
      <form
        id={id}
        className={classes([className, singleInput && '--single-input'])}
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={() => methods.reset(defaultValues)}
        noValidate>
        {React.Children.map(children, child => {
          return child.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  register: methods.register,
                  control: methods.control,
                  errors: methods.formState.errors,
                  key: child.props.name,
                  defaultValues,
                  isGray,
                },
              })
            : child
        })}
      </form>
    </FormProvider>
  )
}

export default Form
