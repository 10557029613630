import React, { useState, useEffect } from 'react'
import { useForm, useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import Preview from './preview'

const FileUploadBase = props => {
  const {
    register,
    unregister,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext()
  const {
    name,
    maxNumOfFiles = 1,
    multiple = maxNumOfFiles === 1 ? false : true,
    accept = 'image/png, image/jpeg',
    maxFileSizeInMb = 2,
  } = props

  const [files, setFiles] = useState([])
  const [updating, setUpdating] = useState(false)

  // We don't care about FileList, we are handling the values through our controlled input
  const handleOnChange = e => {
    const targetFiles = Array.from(e.target.files)
    const mbMax = maxFileSizeInMb * 750000

    let files = targetFiles.reduce((acc, file, index) => {
      if (!file) return false
      if (file.size >= mbMax) {
        alert(`Please lower file size to below ${mbMax}mb`)
        // setError(name, { type: 'manual', message: 'Please lower file size to below 1.5mb' })
        return (e.target.value = '')
      }
      if (index + 1 > maxNumOfFiles) {
        alert(`Exceeded max file limit of ${maxNumOfFiles}!`)
        // setError(name, { type: 'manual', message: `Exceeded max file limit of ${maxNumOfFiles}!` })
        return (e.target.value = '')
      }

      // Keep in mind, we expect url when uploading, and fetching existing data
      const url = URL.createObjectURL(file)
      return [...acc, { file, url }]
    }, [])

    setFiles(files)
    setValue(name, files, { shouldValidate: true, shouldDirty: true })
  }

  // Custom register, since we don't actually need to ref an input type
  useEffect(() => {
    register(name)

    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  // Have to manually check/pass default values
  useEffect(() => {
    const value = props?.defaultValues?.[name]

    if (value) {
      setFiles(value)
      setValue(name, value, { shouldValidate: true, shouldDirty: true })
    }

    return () => {}
  }, [props?.defaultValues])

  return (
    <>
      <input
        id={`file-upload-${name}`}
        type="file"
        className="file-upload-container--input"
        accept={accept}
        multiple={multiple}
        onChange={handleOnChange}
      />

      <label className="file-upload-container--label" htmlFor={`file-upload-${name}`}>
        {files?.length ? <Preview files={files} /> : <></>}
      </label>

      {/* display the file input button if you can add additional files */}
      {files?.length < maxNumOfFiles && (
        <div className="file-upload-container">
          <label className="file-upload-container--label__button" htmlFor={`file-upload-${name}`}>
            <p>Upload Photo</p>
          </label>
        </div>
      )}

      <p className="BEURO__body BEURO__body--small state-label">
        <ErrorMessage errors={errors} name={name}>
          {errors.message}
        </ErrorMessage>
      </p>
    </>
  )
}

const FileUpload = props => {
  const { name } = props
  const { control } = useForm()

  return <Controller control={control} name={name} render={() => <FileUploadBase {...props} />} />
}

export default FileUpload
